export default {
    activate_student: "Активировать студента",
    active: "Активен",
    add: "Добавить",
    add_word: "Добавлено в словарь",
    add_another_lesson_time: "Добавить время урока",
    add_answer: "Добавить ответ",
    add_audio: "Добавить аудио",
    add_block: "Добавить блок",
    add_bot: "Добавить бота",
    add_card: "Добавить карточку",
    add_class: "Добавить урок",
    add_column: "Добавить колонку",
    add_course: "Добавить курс",
    add_exercise: "Добавить упражнение",
    add_group: "Добавить группу",
    add_lesson: "Добавить урок",
    add_material: "Добавить урок",
    add_module: "Добавить модуль",
    add_new_schedule: "Добавить урок",
    add_note: "Добавить пометку",
    add_own_version: "Добавить свою версию",
    add_pair: "Добавить пару",
    add_payment: "Добавить платеж",
    add_photo: "Добавить фото",
    add_presentation: "Добавить презентацию",
    add_program: "Добавить программу",
    add_question: "Добавить вопрос",
    add_sentence: "Добавить предложение",
    add_slide: "Добавить слайд",
    add_staff: "Добавить сотрудника",
    add_statement: "Добавить утверждение",
    add_student: "Добавить студента",
    add_teacher: "Добавить учителя",
    additional: "Дополнительно",
    again: "Заново",
    all: "Все",
    all_courses: "Все курсы",
    all_slides: "Все слайды",
    all_staff: "Все сотрудники",
    all_students: "Все студенты",
    all_teachers: "Все учителя",
    allocate: "Распределить",
    allow_unknown: "Использовать вариант \"неизвестно\"",
    american: "Американский",
    amount_of_cards: "Кол-во карточек",
    answer: "Ответ",
    appId: "ID приложения",
    archive_student: "Архивировать",
    archived: "В архиве",
    are_you_sure_you_want_to_leave_page: "Вы уверены, что хотите покинуть комнату?",
    are_you_sure_you_want_to_delete: "Вы уверены, что хотите удалить материал?",
    are_you_sure_you_want_to_logout: "Вы уверены, что хотите выйти?",
    as_you_go: "По мере прохождения",
    assign_task: "Назначить задание",
    assign_words_to_cards: "Сопоставить карточки и слова",
    attach_photo: "Приложить фото (QR)",
    attended: "Посещение",
    audio: "Аудио",
    audio_config: "Настройки аудио",
    audio_file_requirement: "Файл должен иметь формат .mp3 и не превышать 7 МБ",
    audio_text_img: "Текст, звук, изображение",
    authorization: "Авторизация",
    back: "Назад",
    backcolor: "Цвет фона",
    balance: "Баланс",
    before_class_start_check_settings: "Перед началом урока, убедитесь в качестве подключения",
    blackboard: "Доска",
    block_number: "Номер блока",
    board: "Доска",
    book: "Книга",
    bot: "Бот",
    bot_additional_instructions: "Дополнительная инструкция",
    bot_additional_knowledge: "Дополнительные данные для бота",
    bottom: "Снизу",
    bring_your_friends: "Приводи друзей и получи 5000 тенге на свою карту",
    bring_your_friends_details: "Отправьте ссылку другу - после первой оплаты вы получите 5000 тг на свою карту",
    british: "Британский",
    button_grid: "Кнопки сетка",
    button_vertical: "Кнопки вертикально",
    by_pressing_you_agree: "Нажимая кнопку “Отправить”, вы соглашаетесь с <a href='https://skilllms.com/privacypolicy'>политикой обработки данных</a>",
    camera: "Камера",
    can_admin_payments: "Право администрировать платежи",
    cancel: "Отмена",
    card: "Карточка",
    cards: "Карточки",
    cells_height: "Высота ячеек",
    cells_width: "Ширина ячеек",
    center: "Центр",
    change: "Изменить",
    change_success: "Изменено успешно",
    change_password: "Изменить пароль",
    change_program: "Изменить программу",
    chat: "Чат",
    chat_history: "История чатов",
    check: "Проверить",
    checked: "Проверен",
    choose_a_student: "Выберите студента",
    choose_a_teacher: "Выберите учителя",
    choose_element: "Выберите элемент",
    choose_right_variant: "Выберите правильный вариант",
    choose_screen_to_share: "Выберите экран для демонстрации",
    choose_slide: "Выберите слайд",
    class_added: "Класс добавлен",
    class_removed: "Класс удален",
    class_updated: "Класс изменен",
    close: "Закрыть",
    closed: "Закрыт",
    collect_word_from_letters: "Составить слов из букв",
    column: "Колонка",
    columns_count: "Число колонок",
    colwidth: "Ширина",
    continue: "Продолжить",
    comment: "Комментарий",
    common_room: "Общая комната",
    coming_lessons: "Предстоящие уроки",
    completed: "Завершен",
    completed_adj: "Завершенные",
    completed_lessons: "Завершенные уроки",
    component_is_hidden: "Компонент спрятан",
    connection_speed: "Скорость соединения",
    connection_type: "Тип соединения",
    contacts: "Контакты",
    copied: "Скопировано",
    copy: "Копировать",
    copy_unauthorized_link: "Копировать ссылку для неавторизованных пользователей",
    correct: "Верно",
    correct_answer: "Правильный ответ",
    course: "Курс",
    course_closed: "Курс закрыт",
    course_changed: "Курс изменен",
    course_deleted: "Курс удален",
    courses: "Курсы",
    create_card: "Создать карточку",
    create_new_lesson: "Создать новый урок",
    create_price_policy: "Создать ценовую политику",
    create_quick_lesson: "Создать сессию",
    create_sentence: "Составить предложение из предложенных слов",
    create_sentence_hint: "Напишите предложение, разделив слова знаком '|'. Например, He|likes|apples. Можно добавлять ненужные в предложении слова с префиксом *. Например, He|likes|*like|*liking|apples.",
    currency: "Валюта",
    current_lessons: "Текущие уроки",
    custom_courses: "Мои курсы",
    data_saved: "Данные сохранены",
    delete: "Удалить",
    description: "Описание",
    done: "Готово",
    down: "Вниз",
    edit: "Редактировать",
    edit_class: "Редактировать урок",
    edit_course: "Редактировать курс",
    edit_exercise: "Редактировать упражнение",
    edit_lesson: "Редактировать урок",
    edit_material: "Редактировать урок",
    edit_module: "Редактировать модуль",
    edit_price_policy: "Редактировать ценовую политику",
    email: "Email",
    email_with_further_instructions_is_sent_to_your_email: "Письмо с дальнейшими инструкциями было отправлено на Ваш email",
    empty: "Пусто",
    english: "Английский",
    enggo: "SkillLMS",
    skilllms: "SkillLMS",
    enter_class: "Войти в класс",
    examination: "Проверка",
    exercise: "Упражнение",
    exit: "Выйти",
    failed: "Провал",
    false: "Неверно",
    false_title: "Название кнопки 'неверно'",
    female: "Жен",
    file: "Файл",
    file_does_not_exist: "Файл не найден",
    file_size_is_too_big: "Размер файла слишком велик",
    filter: "Фильтр",
    filter_by_teacher: "Фильтр по преподавателям",
    find_errors: "Найти ошибки",
    find_errors_requirements: "Напишите текст с неправильной|правильной формами слова, разделенными знаком '|' в квадратных скобках. Например, He [liking|likes] apples.",
    finish: "Завершить",
    finish_homework_review: "Завершить проверку ДЗ",
    finish_lesson: "Завершить урок",
    finish_lesson_and_rate_student: "Завершить урок и оценить студентов",
    finished: "Окончен",
    first_lesson_free_and_we_tell: "Бесплатно проведем первый урок, все покажем и расскажем",
    first_you_need_to_add_students: "Вначале вам необходимо добавить студентов",
    follow_me: "Мой слайд",
    forgot_password: "Забыли пароль?",
    friend_opens_link: "Друг переходит по ссылке и оставляет свои данные",
    friend_pays_for_classes: "Друг оплачивает занятия, а вы получаете вознаграждение",
    full_student_search: "Поиск по группам, учителям, студентам",
    general_information: "Общая информация",
    good: "Хорошо",
    group: "Группа",
    group_added: "Группа добавлена",
    group_changed: "Группа изменена",
    group_deleted: "Группа удалена",
    groups: "Группы",
    groups_to_which_student_is_added: "Группы, в которых участвует студент",
    hello: "Привет",
    hello_teacher: "Привет, учитель",
    hide: "Спрятать",
    hide_answers: "Спрятать ответы",
    history: "История",
    home: "Главная",
    homework: "Домашняя работа",
    homework_done: "Завершен",
    homework_do_homework: "Выполнить ДЗ",
    homework_is_waiting_for_you: "Домашняя работа по данному уроку уже ждет вас в личном кабинете",
    homework_short: "Д/з",
    how_it_works: "Как это работает",
    how_would_you_like_chat_to_respond: "Как бы вы хотели, чтобы чат отвечал на сообщения?",
    image: "Изображение",
    image_position: "Расположение изображения",
    image_requirements: "Формат — JPEG или PNG, вес — не более 5 мегабайт",
    incorrect: "Неверно",
    input_field: "Поле для ввода текста",
    input_from_user: "Поле для ввода текста для пользователя",
    insert_missing_drag: "Перенести пропущенные слова",
    insert_missing_drag_requirements: "Напишите текст с пропущенными словами в квадратных скобках []. Также можно добавлять неверные варианты со звездочкой. Неверные ответы должны быть расположены в конце. Например, I [like] apples[liking*].",
    insert_missing_open: "Написать пропущенные слова",
    insert_missing_select: "Выбрать пропущенные варианты",
    insert_missing_select_requirements: "Напишите текст с пропущенными словами в квадратных скобках []. Разделите варианты символом | и пометьте правильный вариант символом *. Например, He [like|likes*|liking] apples.",
    insert_missing_open_requirements: "Напишите текст с пропущенными словами в квадратных скобках []. Например, I [like] apples. Если правильных вариантов несколько, то через символ |. Например, I [did not|didn't] go. Жирный текст ставится в звездочки. Например, what *was* that?",
    integrations: "Интеграции",
    interface_language: "Язык интерфейса",
    internet: "Интернет",
    internet_speed_cannot_be_measured: "Скорость соединения не может быть определена",
    invitation_link: "Ссылка приглашения",
    invitation_sent: "Приглашение отправлено",
    invitation_sent_to_student: "Приглашение было отправлено студенту на email с дальнейшими инструкциями",
    invitation_sent_to_teacher: "Приглашение было отправлено сотруднику на email с дальнейшими инструкциями",
    irregular_verbs: "Неправильные глаголы",
    kazakh: "Казахский",
    keyId: "ID ключа",
    KZT: "KZT",
    language: "Язык",
    launch_timer: "Запустить таймер",
    learned: "Изучено",
    learning: "В процессе изучения",
    leave_your_data_and_we_contact_you: "Оставьте ваши данные и мы свяжемся с вами",
    left: "Слева",
    leftoffset: "Отступ слева",
    les_left: "ур. осталось",
    lesson: "Урок",
    lesson_already_begun: "Урок уже начался",
    lesson_closed: "Урок закрыт",
    lesson_constructor: "Конструктор урока",
    lesson_not_found: "Урок не найден",
    lessons: "Уроки",
    lesson_has_no_program: "У данного урока не указана программа",
    level: "Уровень",
    light: "Light",
    light_failed_text: "Вы потратили все попытки, попробуйте еще раз, у вас обязательно получится!",
    lightcourse: "Light курс",
    lightcourses: "Light курсы",
    link_for_students: "Ссылка для студентов",
    link_words: "Сопоставить слова",
    login: "Логин",
    login_details: "Детали авторизации",
    login_or_pass_incorrect: "Логин и / или пароль неверны",
    login_page: "Страница авторизации",
    logout: "Выйти",
    look_and_remember: "Смотрим и запоминаем",
    main: "Главная",
    male: "Муж",
    manager: "Менеджер",
    managers: "Менеджеры",
    material: "Из урока",
    material_changed: "Урок изменен",
    material_deleted: "Урок удален",
    materials: "Курсы",
    mediumcourse: "Medium курс",
    memorized: "Запомнил(-а)",
    message: "Сообщение",
    methodics: "Методика",
    methodist: "Методист",
    methodists: "Методисты",
    microphone: "Микрофон",
    min: "мин",
    mine: "Мой",
    minutes: "Минут",
    module: "Модуль",
    modules: "Модули",
    more: "Еще",
    mute_microfon: "Выключить микрофон",
    my_accomplishments: "Мои достижения",
    my_new_material: "Мой новый курс",
    name: "Имя",
    next: "Далее",
    new_group: "Новая группа",
    new_password: "Новый пароль",
    new_schedule: "Новый урок",
    new_student: "Новый студент",
    next_lesson: "Следующий урок",
    no_data_available: "Данных пока нет",
    no_files_uploaded: "Файлы не загружены",
    no_lessons_planned: "Уроков пока не запланировано",
    no_program: "Без программы",
    no_students: "Нет студентов",
    no_words_added: "Слова в урок пока не добавлены",
    not_attended: "Отсутствовал(-а)",
    not_checked: "Не проверено",
    not_done: "Не сделана",
    not_finished: "Не пройденные",
    not_found: "Данные не найдены",
    not_memorized: "Не запомнил(-а)",
    not_reviewed: "Не проверенные",
    note: "Пометка",
    nothing_found: "Ничего не найдено",
    off: "Выкл",
    on: "Вкл",
    only_video: "Только видео",
    of_students: "Студентов",
    old_password: "Старый пароль",
    open: "Открыть",
    open_access: "Открыть доступ",
    open_lesson: "Открыть урок",
    opened: "Открытый",
    opened_lesson: "Открытый урок",
    operations: "Операций",
    options: "Варианты",
    order: "Порядок",
    paddingbottom: "Отступ снизу",
    paddingtop: "Отступ сверху",
    pair_hint: "Слово один будет отображено слева, слово два справа. Слово справа можно оставлять пустым, тогда у слова слева не будет правильного ответа. Также в слове справа можно указывать несколько слов, разделенных симоволом | (например, good|awesome), тогда любое из этих слов будет правильным для слова слева",
    pairs: "Пары",
    page_not_found: "Страница не найдена",
    password: "Пароль",
    password_changed: "Пароль изменен",
    password_confirm: "Подтверждение пароля",
    passwords_do_not_match: "Пароли не совпадают",
    pause_student: "Приостановить студента",
    paused: "Приостановлен",
    payment_deleted: "Оплата удалена",
    payments: "Оплаты",
    pdf: "PDF",
    personal_courses: "Мои курсы",
    phone: "Телефон",
    photo: "Фото",
    photos_from_students: "Фото от студентов",
    pitch: "Высота",
    please_select_the_course: "Выберите курс, пожалуйста",
    presentation: "Презентация",
    presentation_short: "Презен-я",
    press_on_user_to_follow_him: "Нажмите на видео студента, чтобы смотреть его выполнение заданий",
    preview: "Предпросмотр",
    previous_lesson: "Предыдущий урок",
    price: "Цена",
    price_per_lesson: "Цена за урок",
    price_policies: "Ценовые политики",
    price_policy: "Ценовая политика",
    price_policy_deleted: "Ценовая политика удалена",
    price_policy_info: "Ценовая политика - это стоимость за один урок для студента. Вы можете создать одну или несколько ценовых политик. У каждого студента есть условный баланс, который вы можете пополнять из раздела \"Платежи\", списание с условного баланса происходит после проведения урока по указанной стоимости в ценовой политике.",
    privateKey: "Приватный ключ",
    program: "Программа",
    progress: "Прогресс",
    profile: "Профиль",
    question: "Вопрос",
    question_text: "Текст вопроса",
    questions: "Вопросы",
    quick_actions: "Быстрые действия",
    quick_call: "Быстрый звонок",
    radio: "Радио вертикально",
    rate: "Скорость",
    rate_students: "Оцените студентов",
    record_audio: "Запись аудио",
    recording: "Запись",
    referral_system: "Реферальная система",
    refresh: "Обновить",
    registration: "Регистрация",
    repeat: "Повторить",
    repeating: "Повторение",
    resend_invite: "Отправить приглашение",
    repeat_weekly: "Повторять каждую неделю",
    report: "Репорт",
    reset: "Сброс",
    reset_password: "Сброс пароля",
    reset_success: "Письмо для сброса отправлено на ваш E-mail",
    return_all: "Вернуть всех",
    reviewed: "Проверена",
    right: "Справа",
    rights: "Права",
    role: "Роль",
    room: "Комната",
    rooms: "Комнаты",
    rows_count: "Число строк",
    russian: "Русский",
    redirect_pp: "Открыть ценовые политики",
    save: "Сохранить",
    save_and_add_new_program: "Сохранить и добавить новую программу",
    scan_qr_code_or: "Сканируйте QR-код с вашего мобильного или",
    schedule: "Расписание",
    schedule_is_empty: "Нет уроков",
    school: "Школа",
    school_courses: "Курсы школы",
    school_language: "Язык школы",
    score: "Баллов",
    scores: "Баллы",
    script: "Скрипт",
    search: "Поиск",
    seconds: "Секунд",
    see_responses: "Посмотреть ответы",
    see_translation: "Посмотреть перевод",
    select: "Выбрать",
    select_course: "Выбрать курс",
    select_topics: "Выберите темы урока",
    selected: "Выбран",
    selfstudy: "Самообучение",
    sentence: "Предложение",
    sentences: "Предложения",
    session_halls: "Сессионные залы",
    set_password: "Установка пароля",
    settings: "Настройки",
    signin: "Войти",
    share_link_with_friend: "Вы делитесь ссылкой с вашим другом",
    show: "Показать",
    show_answers: "Показывать ответы студентам",
    show_answers2: "Показать ответы",
    show_schedule_from: "Показывать расписание от",
    show_schedule_to: "Показывать расписание до",
    show_script_to_users: "Показывать скрипт студентам",
    slide: "Слайд",
    slide_changed: "Слайд изменен",
    slide_deleted: "Слайд удален",
    slide_not_selected: "Слайд не выбран",
    sort_by_columns: "Отсортировать слова по колонкам",
    sort_by_columns_hint: "Укажите название колонки и перечислите все слова, относящиеся к этой колонке, разделенные символом | . Например, He|She|They",
    speaker: "Динамик",
    staff: "Сотрудники",
    standard: "Стандартный",
    standard_courses: "Стандартные курсы",
    start: "Начать",
    start_test: "Начать тест",
    started_lesson: "Урок начался",
    step: "Шаг",
    students_will_be_returned_after: "Возврат студентов произойдет через",
    save_and_start: "Сохранить и начать",
    start_recording: "Начать запись",
    start_training: "Начать тренировку",
    statement: "Утверждение",
    statements: "Утверждения",
    status: "Статус",
    step_x_out_of: "Шаг {step} из {steps}",
    stop_and_play: "Прослушать",
    streaming_type: "Тип трансляции",
    student_added: "Студент добавлен",
    student_changed: "Студент изменен",
    student_deleted: "Студент удален",
    student_note: "Пометка студента",
    student_responses: "Ответы студентов",
    students: "Студенты",
    students_in_group: "Студентов в группе",
    subscription: "Подписка",
    subtitle: "Подпись",
    sum: "Сумма",
    table: "Таблица",
    table_conditions_hint: "Ответы, которые должны заполнять студенты, заключать в квадратные скобки. Например: [answer]. Текст, который должен отображаться жирным шрифтом, заключать в звездочки. Например: *bold word*. Чтобы создать поле для ввода текста студента без правильного ответа, достаточно ввести в ячейку []",
    table_size: "Размер таблицы",
    tariff: "Тариф",
    task: "Задание",
    task_conditions: "Условия упражнения",
    teacher: "Учитель",
    teachers: "Учителя",
    teacher_changed: "Сотрудник изменен",
    teacher_courses: "Курсы учителей",
    teacher_deleted: "Сотрудник удален",
    teacher_note: "Заметка учителя",
    test: "Тест",
    test_connection: "Тест подключения",
    test_question_hint: "Заполните вопросы и минимум 2 варианта ответа. Правильный вариант / варианты отметьте галочками",
    text: "Текст",
    textalign: "Выравнивание текста",
    textcolor: "Цвет текста",
    time_limit: "Ограничение по времени",
    timer: "Таймер",
    timezone: "Часовой пояс",
    title: "Название",
    to_school_site: "На сайт школы",
    today: "Сегодня",
    today_schedule: "Расписание на сегодня",
    top: "Сверху",
    topics: "Темы",
    total: "Итого",
    training: "Тренировка",
    transcription: "Транскрипция",
    translate_to_all: "Транслировать всем",
    translation: "Перевод",
    true: "Верно",
    true_false_hint: "Добавьте утверждения и отметьте правильный статус: верно, неверно, неизвестно",
    true_or_false: "Выбрать правду или ложь",
    true_title: "Название кнопки 'верно'",
    turn_off_video: "Выключить видео",
    turn_on_microphone: "Включить микрофон",
    turn_on_video: "Включить видео",
    type_missing_letters: "Вставить пропущенные буквы",
    type_missing_letters_requirements: "Напишите слово / словосочетание и добавьте символ * после каждой буквы, которая должна быть спрятана. Например, He li*ke*s a*pples.",
    type_text: "Сочинение",
    type_words_by_letters: "Печать слова по буквам",
    underline_requirements: "Напишите текст и поместите слова, которые должны быть подчеркнуты, в квадратные скобки []. К требующим двойного подчеркивания также добавить звездочку перед словом, для обводки восклицательный знак. Например, I [like] apples and he [*likes] [!bananas]. Если вы хотите, чтобы слова, не требующие подчеркивания, выделялись группой, поместите их в фигурные скобки {} (например, {it was raining}, будет отображаться как it was raining и выделяться одной группой)",
    underline_words: "Подчеркнуть слова",
    unknown: "Неизвестно",
    up: "Вверх",
    upload: "Загрузка",
    use_as_default: "Использовать по умолчанию",
    verbs: "Глаголы",
    video: "Видео",
    video_id_on_youtube: "Id видео на youtube",
    video_url: "Url видео",
    video_url_requirement: "Url видео с youtube (например, https://www.youtube.com/watch?v=ZwAERaRUsp0)",
    view: "Просмотр",
    view2: "Вид",
    visible_to_all: "Виден всем",
    visibility_options: "Настройки доступа",
    visible_to_students: "Видна студентам",
    vocabulary: "Словарь",
    way_of_passing: "Способ прохождения",
    well_contact_soon: "Скоро мы с вами свяжемся",
    well_done: "Отлично",
    what_would_you_like_chat_to_know_about: "Что бы вы хотели, чтобы чат дополнительно знал?",
    when_students_upload_photos_they_will_appear_here: "Здесь появятся фото, как только студенты их загрузят",
    word: "Слово",
    word_1: "Слово 1",
    word_2: "Слово 2",
    word_cards: "Языковые карточки",
    words: "Слова",
    words_for_column: "Слова этой колонки",
    words_training: "Тренинг слов",
    workplace: "Место работы",
    write_off_lives: "Списывать жизни",
    yes: "Да",
    you_dont_have_competed_lessons: "Вы пока не прошли ни одного урока",
    you_have_learned: "Вы выучили",
    you_have_no_groups: "У вас нет групп",
    you_need_to_create_at_least_two_students_to_form_group: "Вам нужно завести минимум 2-х студентов для формирования группы",
    your_account_is_already_in_the_class: "Пользователь под вашим аккаунтом уже находится в классе",
    your_application_sent_successfully: "Ваша заявка отправлена!",
    your_device_does_not_support_video: "Ваше устройство не поддерживает технологию видеосвязи - пожалуйста, используйте браузер Safari",
    your_friend_invites_you: "Ваш друг {name} приглашает вас на обучение английскому в онлайн-школе SkillLMS",
    your_homework_is_sent_for_review: "Ваше домашнее задание отправлено на проверку учителю!",
    your_schedule_is_empty: "У вас нет уроков сегодня",
    your_teacher: "Ваш учитель",
    school_student_limit_exceeded: "Превышен лимит доступных учеников в школе."
}
