import { LessonsService } from '@/services/api.service'
import Vue from 'vue'

const state = {
    host:           false,
    hostPeerId:     null,
    hostId:         null,
    myPeerId:       null,
    selectedUser:   null,
    sidebarshow:    false,
    bottomBurger:   false,
    lessonId:       null,
    materialId:     null,
    material:       null,
    selfStudyReviewed: false,
    blockId:        null,
    tasksStore:     {},
    blocksShowingResponses: [],
    studentsBySubrooms: [],
    viewMode:       'lesson',
    methodics:      'standard',
    lessonType:     'lesson',
    isHomework:     false,
    room:           null,
    linkchatUrl:    null,
    lessonWords:    [],
    lessonCards:    [],
    roomsSecondsLeft: 0,
    showCards:      false,
    blackboardJson: null,
    socket:         null,
    newMessage:     false,
    group:          null,
    slide: {
        "lesson": 0,
        "homework": 0
    },
    lessonModesEnabled:     [],
    hostLessonModesEnabled: [],
    lessonModeFull:     false,
    slides:             [],
    slidemode:          'lesson',
    followed:           false,
    finishedLesson:     false,
    selectedBlockId:    null,
    nextRedirectUrl:    null,
    showIframe:         true,
    showMobileTopRight: false,
    homeworkStudentId:  null,
    school:             null,
    lightRepeat:        false,
}

const getters = {
    getBottomBurger (state) {
        return state.bottomBurger
    },
    isEnggoMethodics(state) {
        return state.viewMode !== 'homework' && (state.methodics === 'Еxplication' || state.methodics === 'explication')
    }
}

const mutations = {
    setHost(state, host) {
        state.host = host
    },
    setHostId(state, hostId) {
        state.hostId = hostId
    },
    setHostPeerId(state, hostPeerId) {
        state.hostPeerId = hostPeerId
    },
    setMyPeerId(state, value) {
        state.myPeerId = value
    },
    setSelectedUser(state, selectedUser) {
        state.selectedUser = selectedUser
    },
    setBottomBurger(state, bottomBurger) {
        state.bottomBurger = bottomBurger
    },
    setLessonId(state, value) {
        state.lessonId = value
    },
    setMaterialId(state, value) {
        state.materialId = value
    },
    setMaterial(state, value) {
        state.material = value
    },
    setBlockId(state, value) {
        state.blockId = value
    },
    setTasksStore(state, data) {
        state.tasksStore = data
    },
    setTasksBlock(state, { blockId, data }) {
        state.tasksStore[blockId] = data
    },
    setBlocksForResponses(state, value) {
        state.blocksShowingResponses = value
    },
    setStudentsBySubrooms(state, value) {
        state.studentsBySubrooms = value
    },
    setBlockForResponses(state, blockId) {
        state.blocksShowingResponses.push(blockId)
    },
    removeBlockForResponses(state, blockId) {
        state.blocksShowingResponses = state.blocksShowingResponses.filter(v => v !== blockId)
    },
    incrementTasksRight(state, blockId) {
        state.tasksStore[blockId].right++
    },
    clearTasksBlock(state, blockId) {
        Vue.delete(state.tasksStore, blockId)
    },
    setViewMode(state, value) {
        state.viewMode = value
    },
    setMethodics(state, value) {
        state.methodics = value
    },
    setLinkchatUrl(state, value) {
        state.linkchatUrl = value
    },
    setSelfStudyReviewed(state, value) {
        state.selfStudyReviewed = value
    },
    setRoomsSecondsLeft(state, value) {
        state.roomsSecondsLeft = value
    },
    setBlackboardJson(state, value) {
        state.blackboardJson = value
    },
    setNewMessage(state, value) {
        state.newMessage = value
    },
    setLessonType(state, value) {
        state.lessonType = value
    },
    setRoom(state, value) {
        state.room = value
    },
    setIsHomework(state, value) {
        state.isHomework = value
    },
    setLessonWords(state, value) {
        state.lessonWords = value
    },
    setLessonCards(state, value) {
        state.lessonCards = value
    },
    setShowIframe(state, value) {
      state.showIframe = value
    },
    setShowCards(state, value) {
        state.showCards = value
        if (state.host && state.socket) {
            state.socket.emit('customevent', 'showcards', state.room, value)
        }
    },
    setSlide(state, value) {
        state.slide = value
    },
    setSlides(state, value) {
        state.slides = value
    },
    setSlideMode(state, value) {
        state.slidemode = value
    },
    setFollowed(state, value) {
        state.followed = value
    },
    setFinishedLesson(state, value) {
        state.finishedLesson = value
    },
    setSpecificSlide(state, value) {
        state.slide[state.slidemode] = value
    },
    setLessonModesEnabled(state, value) {
      state.lessonModesEnabled = value
    },
    setHostLessonModesEnabled(state, value) {
        state.hostLessonModesEnabled = value
    },
    setSocket(state, value) {
        state.socket = value
    },
    setLessonModeFull(state, value) {
        state.lessonModeFull = value
    },
    setNextRedirectUrl(state, value) {
        state.nextRedirectUrl = value
    },
    setHomeworkStudentId(state, value) {
        state.homeWorkStudentId = value
    },
    toggleLessonMode(state, value) {
        const isMobile = this.state.isMobile
        if (!state.lessonModesEnabled.includes(value)) {
            if(state.lessonModesEnabled.length > (isMobile ? 0 : 1)) {
                state.lessonModesEnabled.shift()
            }
            state.lessonModesEnabled.push(value);
        } else {
            state.lessonModesEnabled.splice(state.lessonModesEnabled.indexOf(value), 1)
        }
        state.lessonFullMode = false
    },
    expandLessonMode(state, value) {
        if(state.lessonModesEnabled.includes(value) && state.lessonModeFull === true) { // means collapsing, not expanding
            state.lessonModeFull = false
            return
        }
        state.lessonModesEnabled = []
        state.lessonModesEnabled.push(value);
        state.lessonModeFull = true
    },
    switchSideLessonMode(state, value) {
        let index = state.lessonModesEnabled.indexOf(value)
        if(index === 0) {
            state.lessonModesEnabled.shift()
            state.lessonModesEnabled.push(value)
        }
        if(index === 1) {
            state.lessonModesEnabled.splice(index, 1)
            state.lessonModesEnabled.unshift(value)
        }
        state.lessonModeFull = false
    },
    setSelectedBlockId(state, value) {
        state.selectedBlockId = value
    },
    toggleShowMobileTopRight(state) {
        state.showMobileTopRight = !state.showMobileTopRight
    },
    setLessonSchool(state, value) {
        state.school = value
    },
    setLessonGroup(state, value) {
        state.group = value
    },
    setLightRepeat(state, value) {
        state.lightRepeat = value
    }
}

const actions = {
    getUserTasks(context, { id, type, user_id }) {
        return new Promise((resolve, reject) => {
            LessonsService.retrieveTaskStorage(id, type, user_id).then(data => {
                let d = data.data.data
                // console.log(d)
                context.commit('setTasksStore', d)
                resolve(d)
            }).catch(err => {
                reject(err.response ? err.response.data.errors : [])
            })
        })
    },
    saveTaskStorageBlock(context, { blockId, lessonId, materialId, type, data }) {
        context.commit('setTasksBlock', { blockId, data })
        const homeWorkStudentId = context.state.homeWorkStudentId
        if(['lesson', 'demo'].includes(context.state.lessonType) && !context.state.lightRepeat) {
            LessonsService.saveTaskStorage({
                material_id: materialId,
                type: type === 'selfstudy' ? 'selfstudy' : 'lesson',
                lesson_id: lessonId,
                block_id: blockId,
                data: data,
                homeWorkStudentId
            })
        }
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}
