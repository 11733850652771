export default {
    activate_student: "Activate student",
    active: "Active",
    add: "Add",
    add_word: "Added to dictionary",
    add_another_lesson_time: "Add another lesson time",
    add_answer: "Add answer",
    add_audio: "Add audio",
    add_block: "Add block",
    add_bot: "Add bot",
    add_card: "Add card",
    add_class: "Add class",
    add_column: "Add column",
    add_course: "Add course",
    add_exercise: "Add exercise",
    add_group: "Add group",
    add_lesson: "Add lesson",
    add_material: "Add material",
    add_module: "Add module",
    add_new_schedule: "Add new schedule",
    add_note: "Add note",
    add_own_version: "Add own version",
    add_pair: "Add pair",
    add_payment: "Add payment",
    add_photo: "Add photo",
    add_presentation: "Add presentation",
    add_program: "Add program",
    add_question: "Add question",
    add_sentence: "Add sentence",
    add_slide: "Add slide",
    add_staff: "Add staff",
    add_statement: "Add statement",
    add_student: "Add student",
    add_teacher: "Add teacher",
    additional: "Additional",
    again: "Again",
    all: "All",
    all_courses: "All courses",
    all_slides: "All slides",
    all_staff: "All staff",
    all_students: "All students",
    all_teachers: "All teachers",
    allocate: "Allocate",
    allow_unknown: "Use variant \"unknown\"",
    american: "American",
    amount_of_cards: "Amount of cards",
    answer: "Answer",
    appId: "App ID",
    archive_student: "Archive student",
    archived: "Archived",
    are_you_sure_you_want_to_leave_page: "Are you sure you want to leave the room?",
    are_you_sure_you_want_to_delete: "Are you sure you want to delete the material?",
    are_you_sure_you_want_to_logout: "Are you sure you want to logout?",
    as_you_go: "As you go",
    assign_task: "Assign task",
    assign_words_to_cards: "Assign words to cards",
    attach_photo: "Attach photo (QR)",
    attended: "Attended",
    audio: "Audio",
    audio_config: "Audio config",
    audio_file_requirement: "File should have .mp3 format and be no more than 7 MB",
    audio_text_img: "Text, sound and image",
    authorization: "Authorization",
    back: "Back",
    backcolor: "Back color",
    balance: "Balance",
    before_class_start_check_settings: "Before class starts, test your connection settings",
    blackboard: "Blackboard",
    block_number: "Block number",
    board: "Board",
    book: "Book",
    bot: "Bot",
    bot_additional_instructions: "Additional instructions",
    bot_additional_knowledge: "Additional data for bot",
    bottom: "Bottom",
    bring_your_friends: "Bring your friends and get 5 000 KZT on your card",
    bring_your_friends_details: "Send a link to a friend - after first payment you get 5 000 KZT on your card",
    british: "British",
    button_grid: "Button grid",
    button_vertical: "Button vertical",
    by_pressing_you_agree: "By pressing “Send” button you agree with <a href='https://skilllms.com/privacypolicy'>privacy policy</a>",
    camera: "Camera",
    can_admin_payments: "Has right to administer payments",
    cancel: "Cancel",
    card: "Card",
    cards: "Cards",
    cells_height: "Cells height",
    cells_width: "Cells width",
    center: "Center",
    change: "Change",
    change_success: "Changed successfully",
    change_password: "Change password",
    change_program: "Change program",
    chat: "Chat",
    chat_history: "Chat History",
    check: "Check",
    checked: "Checked",
    choose_a_student: "Choose a student",
    choose_a_teacher: "Choose a teacher",
    choose_element: "Choose element",
    choose_right_variant: "Choose right variant",
    choose_screen_to_share: "Choose screen to share",
    choose_slide: "Choose slide",
    class_added: "Class added",
    class_removed: "Class removed",
    class_updated: "Class updated",
    close: "Close",
    closed: "Closed",
    collect_word_from_letters: "Collect words from letters",
    column: "Column",
    columns_count: "Число столбцов",
    colwidth: "Width",
    continue: "Continue",
    comment: "Comment",
    common_room: "Common room",
    coming_lessons: "Coming lessons",
    completed: "Completed",
    completed_adj: "Completed",
    completed_lessons: "Completed lessons",
    component_is_hidden: "Component is hidden",
    connection_speed: "Connection speed",
    connection_type: "Connection type",
    contacts: "Contacts",
    copied: "Copied",
    copy: "Copy",
    copy_unauthorized_link: "Copy link for unauthorized users",
    correct: "Correct",
    correct_answer: "Correct answer",
    course: "Course",
    course_closed: "Course closed",
    course_changed: "Course changed",
    course_deleted: "Course deleted",
    courses: "Courses",
    create_card: "Create card",
    create_new_lesson: "Create new lesson",
    create_price_policy: "Create price policy",
    create_quick_lesson: "Create quick lesson",
    create_sentence: "Make a sentence from suggested words",
    create_sentence_hint: "Write sentence with each word separated by '|' sign. For example, He|likes|apples. There is a possibility to add idle words by adding * prefix. For example, He|likes|*like|*liking|apples.",
    currency: "Currency",
    current_lessons: "Current lessons",
    custom_courses: "Custom courses",
    data_saved: "Data saved",
    delete: "Delete",
    description: "Description",
    done: "Done",
    down: "Down",
    edit: "Edit",
    edit_class: "Edit class",
    edit_course: "Edit course",
    edit_exercise: "Edit exercise",
    edit_lesson: "Edit lesson",
    edit_material: "Edit material",
    edit_module: "Edit module",
    edit_price_policy: "Edit price policy",
    email: "Email",
    email_with_further_instructions_is_sent_to_your_email: "Email with further instructions was sent to your email",
    empty: "Empty",
    english: "English",
    enggo: "SkillLMS",
    skilllms: "SkillLMS",
    enter_class: "Enter class",
    examination: "Examination",
    exercise: "Exercise",
    exit: "Exit",
    failed: "Failed",
    false: "False",
    false_title: "False button title",
    female: "Female",
    file: "File",
    file_does_not_exist: "File does not exist",
    file_size_is_too_big: "File size is too big",
    filter: "Filter",
    filter_by_teacher: "Filter by teachers",
    find_errors: "Find errors",
    find_errors_requirements: "Write text with correct|incorrect forms of a word separated by '|' in square brackets []. For example, He [liking|likes] apples.",
    finish: "Finish",
    finish_homework_review: "Finish homework review",
    finish_lesson: "Finish lesson",
    finish_lesson_and_rate_student: "Finish the lesson and rate students",
    finished: "Finished",
    first_lesson_free_and_we_tell: "We'll walk you through our system and give first lesson for free",
    first_you_need_to_add_students: "First of all, you need to add students",
    follow_me: "Follow me",
    forgot_password: "Forgot password?",
    friend_opens_link: "Friend opens the link and fills the data",
    friend_pays_for_classes: "Friend pays for lessons and you get reward",
    full_student_search: "Search by groups, teachers, students",
    general_information: "General information",
    good: "Good",
    group: "Group",
    group_added: "Group added",
    group_changed: "Group changed",
    group_deleted: "Group deleted",
    groups: "Groups",
    groups_to_which_student_is_added: "Groups in which student participates",
    hello: "Hello",
    hello_teacher: "Hello, Teacher",
    hide: "Hide",
    hide_answers: "Hide answers",
    history: "History",
    home: "Home",
    homework: "Homework",
    homework_done: "Homework completed",
    homework_do_homework: "Do homework",
    homework_is_waiting_for_you: "This lesson homework is waiting for you in your student panel",
    homework_short: "H/w",
    how_it_works: "How it works",
    how_would_you_like_chat_to_respond: "How would you like chat to respond?",
    image: "Image",
    image_position: "Image position",
    image_requirements: "Should be JPEG or PNG not more than 5 MB",
    incorrect: "Incorrect",
    input_field: "Input field",
    input_from_user: "Type field for user",
    insert_missing_drag: "Drag missing words",
    insert_missing_drag_requirements: "Write text with missing words in square brackets []. Also wrong words can be added with *. Wrong words should be placed at the end. For example, I [like] apples[liking*].",
    insert_missing_open: "Type missing words",
    insert_missing_select: "Select missing words",
    insert_missing_select_requirements: "Write text with missing words in square brackets []. Separate options with | symbol and mark correct option with *. For example, He [like|likes*|liking] apples.",
    insert_missing_open_requirements: "Write text with missing words in square brackets []. For example, I [like] apples. If there are several answers, list them through |. For example, I [did not|didn't] go. Bold text is tagged with symbol *. For example, what *was* that?",
    integrations: "Integrations",
    interface_language: "Interface language",
    internet: "Internet",
    internet_speed_cannot_be_measured: "Internet speed cannot be measured",
    invitation_link: "Invitation link",
    invitation_sent: "Invitation sent",
    invitation_sent_to_student: "An invitation has been sent to the student's email with further instructions",
    invitation_sent_to_teacher: "An invitation has been sent to the member's email with further instructions",
    irregular_verbs: "Irregular verbs",
    kazakh: "Kazakh",
    keyId: "Key ID",
    KZT: "KZT",
    language: "Language",
    launch_timer: "Launch timer",
    learned: "Learned",
    learning: "Learning",
    leave_your_data_and_we_contact_you: "Leave your data and we'll contact you",
    left: "Left",
    leftoffset: "Left offset",
    les_left: "les. left",
    lesson: "Lesson",
    lesson_already_begun: "Lesson already begun",
    lesson_closed: "Lesson closed",
    lesson_constructor: "Lesson constructor",
    lesson_not_found: "Lesson not found",
    lessons: "Lessons",
    lesson_has_no_program: "The lesson has no program",
    level: "Level",
    light: "Light",
    light_failed_text: "You've run out of lives. Try more and you'll succeed!",
    lightcourse: "Light course",
    lightcourses: "Light courses",
    link_for_students: "Link for students",
    link_words: "Link words",
    login: "Login",
    login_details: "Login details",
    login_or_pass_incorrect: "Login and / or password are incorrect",
    login_page: "Login page",
    logout: "Logout",
    look_and_remember: "Look and memorize",
    main: "Home",
    male: "Male",
    manager: "Manager",
    managers: "Managers",
    material: "From material",
    material_changed: "Lesson changed",
    material_deleted: "Lesson deleted",
    materials: "Materials",
    mediumcourse: "Medium course",
    memorized: "Memorized",
    message: "Message",
    methodics: "Methodics",
    methodist: "Methodist",
    methodists: "Methodists",
    microphone: "Microphone",
    min: "min",
    mine: "Mine",
    minutes: "Minutes",
    module: "Module",
    modules: "Modules",
    more: "More",
    mute_microfon: "Mute microphone",
    my_accomplishments: "My accomplishments",
    my_new_material: "My new material",
    name: "Name",
    next: "Next",
    new_group: "New group",
    new_password: "New password",
    new_schedule: "New schedule",
    new_student: "New student",
    next_lesson: "Next lesson",
    no_data_available: "No data available",
    no_files_uploaded: "No files uploaded",
    no_lessons_planned: "No lessons planned yet",
    no_program: "No program",
    no_students: "No students",
    no_words_added: "No words are added to the course",
    not_attended: "Not attended",
    not_checked: "Not checked",
    not_done: "Not done",
    not_finished: "Not finished",
    not_found: "Data not found",
    not_memorized: "Not memorized",
    not_reviewed: "Not reviewed",
    note: "Note",
    nothing_found: "Nothing found",
    off: "Off",
    on: "On",
    only_video: "Only video",
    of_students: "Students",
    old_password: "Old password",
    open: "Open",
    open_access: "Open access",
    open_lesson: "Open lesson",
    opened: "Opened",
    opened_lesson: "Opened lesson",
    operations: "Operations",
    options: "Options",
    order: "Order",
    paddingbottom: "Padding bottom",
    paddingtop: "Padding top",
    pair_hint: "Word one will be shown in left part, word two on the right. Word on the right can be left empty in case left word has no correct answer. Also you can put several words separated by | (e.g., good|awesome) in a right word field, then any of those words will be correct for the left word.",
    pairs: "Pairs",
    page_not_found: "Page not found",
    password: "Password",
    password_changed: "Password changed",
    password_confirm: "Password confirmation",
    passwords_do_not_match: "Passwords do not match",
    pause_student: "Pause student",
    paused: "Paused",
    payment_deleted: "Payment deleted",
    payments: "Payments",
    pdf: "PDF",
    personal_courses: "Personal courses",
    phone: "Phone",
    photo: "Photo",
    photos_from_students: "Photos from students",
    pitch: "Pitch",
    please_select_the_course: "Please select the course",
    presentation: "Presentation",
    presentation_short: "Pres-n",
    press_on_user_to_follow_him: "Press on user video to follow his tasks",
    preview: "Preview",
    previous_lesson: "Previous lesson",
    price: "Price",
    price_per_lesson: "Price per lesson",
    price_policies: "Price policies",
    price_policy: "Price policy",
    price_policy_deleted: "Price policy deleted",
    price_policy_info: "Pricing policy is the cost per lesson for a student. You can create one or more pricing policies. Each student has a conditional balance, which you can replenish from the \"Payments\" section, the conditional balance is debited after the lesson at the specified cost in the pricing policy.",
    privateKey: "Private key",
    program: "Program",
    progress: "Progress",
    profile: "Profile",
    question: "Question",
    question_text: "Question text",
    questions: "Questions",
    quick_actions: "Quick actions",
    quick_call: "Quick call",
    radio: "Radio vertical",
    rate: "Rate",
    rate_students: "Rate students",
    record_audio: "Record audio",
    recording: "Recording",
    referral_system: "Referral system",
    refresh: "Refresh",
    registration: "Registration",
    repeat: "Repeat",
    repeating: "Repeating",
    resend_invite:"Resend invite",
    repeat_weekly: "Repeat weekly",
    report: "Report",
    reset: "Reset",
    reset_password: "Reset password",
    reset_success: 'Letter with further instructions has been sent to your email',
    return_all: "Return all",
    reviewed: "Reviewed",
    right: "Right",
    rights: "Rights",
    role: "Role",
    room: "Room",
    rooms: "Rooms",
    rows_count: "Rows count",
    russian: "Russian",
    redirect_pp: "Go to pricing policies",
    save: "Save",
    save_and_add_new_program: "Save and add new program",
    scan_qr_code_or: "Scan QR-code from your smartphone or",
    schedule: "Schedule",
    schedule_is_empty: "Schedule is empty",
    school: "School",
    school_courses: "School courses",
    school_language: "School language",
    score: "Score",
    scores: "Scores",
    script: "Script",
    search: "Search",
    seconds: "Seconds",
    see_responses: "See responses",
    see_translation: "See translation",
    select: "Select",
    select_course: "Select course",
    select_topics: "Select topics",
    selected: "Selected",
    selfstudy: "Self-study",
    sentence: "Sentence",
    sentences: "Sentences",
    session_halls: "Session halls",
    set_password: "Set password",
    settings: "Settings",
    signin: "Sign in",
    share_link_with_friend: "You share the link with your friend",
    show: "Show",
    show_answers: "Show answers to students",
    show_answers2: "Show answers",
    show_schedule_from: "Show schedule from",
    show_schedule_to: "Show schedule to",
    show_script_to_users: "Show script to users",
    slide: "Slide",
    slide_changed: "Slide changed",
    slide_deleted: "Slide deleted",
    slide_not_selected: "Slide not selected",
    sort_by_columns: "Sort words by columns",
    sort_by_columns_hint: "Specify a column name and list all words related to this column, separated by the symbol | . For example, He|She|They",
    speaker: "Speaker",
    staff: "Staff",
    standard: "Standard",
    standard_courses: "Standard courses",
    start: "Start",
    start_test: "Start test",
    started_lesson: "Lesson started",
    step: "Step",
    students_will_be_returned_after: "Students will be returned after",
    save_and_start: "Save and start",
    start_recording: "Start recording",
    start_training: "Start training",
    statement: "Statement",
    statements: "Statements",
    status: "Status",
    step_x_out_of: "Step {step} out of {steps}",
    stop_and_play: "Stop and play",
    streaming_type: "Streaming type",
    student_added: "Student added",
    student_changed: "Student changed",
    student_deleted: "Student deleted",
    student_note: "Student note",
    student_responses: "Students' responses",
    students: "Students",
    students_in_group: "Students in group",
    subscription: "Subscription",
    subtitle: "Subtitle",
    sum: "Sum",
    table: "Table",
    table_conditions_hint: "Ответы, которые должны заполнять студенты, заключать в квадратные скобки. Например: [answer]. Текст, который должен отображаться жирным шрифтом, заключать в звездочки. Например: *bold word*. Чтобы создать поле для ввода текста студента без правильного ответа, достаточно ввести в ячейку []",
    table_size: "Table size",
    tariff: "Tariff",
    task: "Task",
    task_conditions: "Task conditions",
    teacher: "Teacher",
    teachers: "Teachers",
    teacher_changed: "Member changed",
    teacher_courses: "Teacher courses",
    teacher_deleted: "Member deleted",
    teacher_note: "Teacher's note",
    test: "Multiple choice",
    test_connection: "Test connection",
    test_question_hint: "Fill in questions and minimum 2 answers. Tick the checkboxes near the right answer / answers.",
    text: "Text",
    textalign: "Text align",
    textcolor: "Text color",
    time_limit: "Time limit",
    timer: "Timer",
    timezone: "Timezone",
    title: "Title",
    to_school_site: "To school site",
    today: "Today",
    today_schedule: "Today Schedule",
    top: "Top",
    topics: "Topics",
    total: "Total",
    training: "Training",
    transcription: "Transcription",
    translate_to_all: "Translate to all",
    translation: "Translation",
    true: "True",
    true_false_hint: "Add statements and select correct status: true, false or unknown",
    true_or_false: "True or false",
    true_title: "True button title",
    turn_off_video: "Turn off video",
    turn_on_microphone: "Turn on microphone",
    turn_on_video: "Turn on video",
    type_missing_letters: "Type missing letters",
    type_missing_letters_requirements: "Type a word / words adding * symbol after each letter that should be hidden. For example, He li*ke*s a*pples.",
    type_text: "Type text",
    type_words_by_letters: "Type words by letters",
    underline_requirements: "Fill the text and put the words which need to be underlined in square brackets []. For those requiring double underlining, also add an asterisk before the word, for circling exclamation mark. For example, I [like] apples and he [*likes] [!bananas]. If you want words to be selected as a group, put them in curly braces {}. For example, {it was raining} will be shown as 'it was raining' and will be selected as a group.",
    underline_words: "Underline words",
    unknown: "Unknown",
    up: "Up",
    upload: "Upload",
    use_as_default: "Use as default",
    verbs: "Verbs",
    video: "Video",
    video_id_on_youtube: "Video id on youtube",
    video_url: "Video url",
    video_url_requirement: "Url video from youtube (for example, https://www.youtube.com/watch?v=ZwAERaRUsp0)",
    view: "View",
    view2: "View",
    visible_to_all: "Visible to all",
    visibility_options: "Visibility options",
    visible_to_students: "Visible to students",
    vocabulary: "Vocabulary",
    way_of_passing: "Pass way",
    well_contact_soon: "We'll contact you soon",
    well_done: "Well done",
    what_would_you_like_chat_to_know_about: "What would you like chat to know about?",
    when_students_upload_photos_they_will_appear_here: "When student upload photos, they will appear here",
    word: "Word",
    word_1: "Word 1",
    word_2: "Word 2",
    word_cards: "Word cards",
    words: "Words",
    words_for_column: "Column words",
    words_training: "Words training",
    workplace: "Workplace",
    write_off_lives: "Write off lives",
    yes: "Yes",
    you_dont_have_competed_lessons: "You have not completed any lessons yet",
    you_have_learned: "You have learned",
    you_have_no_groups: "You have no groups",
    you_need_to_create_at_least_two_students_to_form_group: "You need to create at least two students to form a group",
    your_account_is_already_in_the_class: "User from your account is already in the class",
    your_application_sent_successfully: "Your application sent!",
    your_device_does_not_support_video: "Your device does not support video - use Safari browser",
    your_friend_invites_you: "Your friend {name} is inviting you to study English in SkillLMS online school",
    your_homework_is_sent_for_review: "Your homework is sent for review!",
    your_schedule_is_empty: "Your schedule is empty",
    your_teacher: "Your teacher",
    school_student_limit_exceeded: "The limit of available students at school has been exceeded."
}
