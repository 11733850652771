<template>
  <div id="app">
    <component v-if="loaded" :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<style lang="scss">
@import "assets/sass/app";
</style>

<script>
// import Bowser from "bowser"

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + '-layout'
    }
  },
  data() {
    return {
      loaded: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile)
    this.checkMobile()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile)
  },
  methods: {
    checkMobile() {
      this.$store.commit('setIsMobile', window.innerWidth <= 768)
      const doc = document.documentElement
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    },
  },
  metaInfo: {
    titleTemplate: '%s - SkillLMS'
  }
}
</script>

<style lang="scss" scoped>

@media (max-width: 1024px) {
  #app {
    position: relative;
  }}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
