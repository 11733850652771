export default {
    activate_student: "Студентті активациялау",
    active: "Активті",
    add: "Қосу",
    add_word: "Сөздікке қосылды",
    add_another_lesson_time: "Сабақ уақытын қосу",
    add_answer: "Жауап қосу",
    add_audio: "Аудио қосу",
    add_block: "Блок қосу",
    add_bot: "Бот қосу",
    add_card: "Карточка қосу",
    add_class: "Сабақ қосу",
    add_column: "Баған қосу",
    add_course: "Курс қосу",
    add_exercise: "Жаттығу қосу",
    add_group: "Топ қосу",
    add_lesson: "Сабақ қосу",
    add_material: "Сабақ қосу",
    add_module: "Модульқ қосу",
    add_new_schedule: "Сабақ қосу",
    add_note: "Жазба қосу",
    add_own_version: "Өз нұсқасын қосу",
    add_pair: "Жұп қосу",
    add_payment: "Төлем қосу",
    add_photo: "Фото қосу",
    add_presentation: "Презентация қосу",
    add_program: "Бағдарлама қосу",
    add_question: "Сұрақ қосу",
    add_sentence: "Ұсыныс қосу",
    add_slide: "Слайд қосу",
    add_staff: "Қызметкерді қосу",
    add_statement: "Бекітуді қосу",
    add_student: "Студент қосу",
    add_teacher: "Мұғалім қосу",
    additional: "Қосымша",
    again: "Қайтадан",
    all: "Барлығы",
    all_courses: "Барлық курстар",
    all_slides: "Барлық слайдтар",
    all_staff: "Барлық қызметкерлер",
    all_students: "Барлық студенттер",
    all_teachers: "Барлық мұғалімдер",
    allocate: "Бөлу",
    allow_unknown: "Мына нұсқаларды пайдалану \"белгісіз\"",
    american: "Американдық",
    amount_of_cards: "Карточкалар саны",
    answer: "Жауап",
    appId: "ID қосымшалар",
    archive_student: "Архивтеу",
    archived: "Архивте",
    are_you_sure_you_want_to_leave_page: "Расымен де бөлмеден шыққыңыз келе ме?",
    are_you_sure_you_want_to_delete: "Расымен де материалды өшіргіңіз келе ме?",
    are_you_sure_you_want_to_logout: "Расымен де шыққыңыз келе ме?",
    as_you_go: "Өту шамасына байланысты",
    assign_task: "Тапсырманы белгілеу",
    assign_words_to_cards: "Карточкалар мен сөздерді сәйкестендіру",
    attach_photo: "Фото қою (QR)",
    attended: "Қатысу",
    audio: "Аудио",
    audio_config: "Аудио баптаулары",
    audio_file_requirement: "Файл форматы .mp3 болуы және 7 МБ-тан аспауы қажет",
    audio_text_img: "Мәтін, дыбыс, сурет",
    authorization: "Авторизация",
    back: "Кері",
    backcolor: "Фон түсі",
    balance: "Баланс",
    before_class_start_check_settings: "Сабақ басталмас бұрын байланыстың сапасын тексеріңіз",
    blackboard: "Тақта",
    block_number: "Блок номері",
    board: "Тақта",
    book: "Кітап",
    bot: "Бот",
    bot_additional_instructions: "Ботқа қосымша нұсқаулық",
    bot_additional_knowledge: "Ботқа қосымша білім",
    bottom: "Төменде",
    bring_your_friends: "Достарыңды ертіп келіп, өз картаңа 5000 теңге ал",
    bring_your_friends_details: "Досыңызға сілтеме жіберіңіз-бірінші төлемнен соң өз картаңызға 5000 тг аласыз",
    british: "Британдық",
    button_grid: "Тор коз батырмалары",
    button_vertical: "Көлденең батырмалар",
    by_pressing_you_agree: " “Жіберу” батырмасын басу арқылы сіз <a href='https://skilllms.com/privacypolicy'>мәліметтерді өңдеу саясатымен келісесіз</a>",
    camera: "Камера",
    can_admin_payments: "Төлемдерді әкімдеу ережелері",
    cancel: "Бас тарту",
    card: "Карточка",
    cards: "Карточкалар",
    cells_height: "Торлардың биіктігі",
    cells_width: "Торлардың ені",
    center: "Орталық",
    change: "Өзгерту",
    change_success: "Сәтті өзгертілді",
    change_password: "Құпиясөзді өзгерту",
    change_program: "Бағдарламаны өзгерту",
    chat: "Чат",
    chat_history: "Чаттар тарихы",
    check: "Тексеру",
    checked: "Тексерілген",
    choose_a_student: "Студентті таңдаңыз",
    choose_a_teacher: "Мұғалімді таңдаңыз",
    choose_element: "Элемент таңдаңыз",
    choose_right_variant: "Дұрыс нұсқасын таңдаңыз",
    choose_screen_to_share: "Көрсетілімге арналған экранды таңдаңыз",
    choose_slide: "Слайд таңдаңыз",
    class_added: "Сынып қосылды",
    class_removed: "Сынып өшірілді",
    class_updated: "Сынып өзгертілді",
    close: "Жабу",
    closed: "Жабылды",
    collect_word_from_letters: "Әріптерден сөздер құрастыру",
    column: "Баған",
    columns_count: "Бағандар саны",
    colwidth: "Ені",
    continue: "Жалғастыру",
    comment: "Пікір",
    common_room: "Ортақ бөлме",
    coming_lessons: "Алдағы сабақтар",
    completed: "Аяқталды",
    completed_adj: "Аяқталды",
    completed_lessons: "Аяқталған сабақтар",
    component_is_hidden: "Компонент жасырулы",
    connection_speed: "Қосылу жылдамдығы",
    connection_type: "Қосылу типі",
    contacts: "Контактілар",
    copied: "Көшіріліп алынды",
    copy: "Көшіріп алу",
    copy_unauthorized_link: "Рұқсат етілмеген пайдаланушылар үшін сілтемені көшіріңіз",
    correct: "Дұрыс",
    correct_answer: "Дұрыс жауап",
    course: "Курс",
    course_closed: "Курс жабылды",
    course_changed: "Курс өзгертілді",
    course_deleted: "Курс өшірілді",
    courses: "Курстар",
    create_card: "Карточка жасау",
    create_new_lesson: "Жаңа сабақты жасау",
    create_price_policy: "Баға саясатын жасау",
    create_quick_lesson: "Сессия жасау",
    create_sentence: "Ұсынылған сөздермен сөйлем құрау",
    create_sentence_hint: "Сөздепді '|' таңбасымен бөліп, сөйлемді жазыңыз. Мысалы, He|likes|apples. Сөйлемге керек емес сөздерді * таңбасымен қосуға болады. Мысалы, He|likes|*like|*liking|apples.",
    currency: "Валюта",
    current_lessons: "Ағымдағы сабақтар",
    custom_courses: "Менің курстарым",
    data_saved: "Мәліметтер сақталды",
    delete: "Өшіру",
    description: "Сипаттама",
    done: "Дайын",
    down: "Төменге",
    edit: "Өзгерту",
    edit_class: "Сабақты өзгерту",
    edit_course: "Курсты өзгерту",
    edit_exercise: "Жаттығуды өзгерту",
    edit_lesson: "Сабақты өзгерту",
    edit_material: "Сабақты өзгерту",
    edit_module: "Модульді өзгерту",
    edit_price_policy: "Баға саясатын өзгерту",
    email: "Email",
    email_with_further_instructions_is_sent_to_your_email: "Ендігі әрекеттердің нұсқаулығы сіздің email-ға жіберілді",
    empty: "Бос",
    english: "Ағылшын тілі",
    enggo: "SkillLMS",
    skilllms: "SkillLMS",
    enter_class: "Сыныпқа кіру",
    examination: "Тексеру",
    exercise: "Жаттығу",
    exit: "Шығу",
    failed: "Құлдырау",
    false: "Қате",
    false_title: "Батырманың атауы қате",
    female: "Әйел",
    file: "Файл",
    file_does_not_exist: "Файл табылған жоқ",
    file_size_is_too_big: "Файлдың өлшемі тым үлкен",
    filter: "Фильтр",
    filter_by_teacher: "Мұғалімдер бойынша фильтр",
    find_errors: "Қателерді табу",
    find_errors_requirements: "Напишите текст '|' таңбасымен тік жақшадағы сөздің дұрыс|қате формаларымен мәтін жазыңыз. Мысалы, He [liking|likes] apples.",
    finish: "Аяқтау",
    finish_homework_review: "ҮТ тексеруді аяқтау",
    finish_lesson: "Сабақты аяқтау",
    finish_lesson_and_rate_student: "Сабақты аяқтап, студенттерді бағалау",
    finished: "Аяқталды",
    first_lesson_free_and_we_tell: "Бірінші сабақты тегін өткізіп, бәрін көрсетеміз, бәрін айтамыз",
    first_you_need_to_add_students: "Алдымен студенттер қосуыңыз керек",
    follow_me: "Менің слайдым",
    forgot_password: "Құпиясөзді ұмыттыңыз ба?",
    friend_opens_link: "Досыңыз сілтемемен өтіп, өзі туралы мәліметтерді қалдырады",
    friend_pays_for_classes: "Досыңыз сабақты төлейді, ал сіз төлемақы аласыз",
    full_student_search: "Топтар, мұғалімдер, студенттер бойынша іздеу",
    general_information: "Ортақ ақпарат",
    good: "Жақсы",
    group: "Топ",
    group_added: "Топ қосылды",
    group_changed: "Топ өзгертілді",
    group_deleted: "Топ өшірілді",
    groups: "Топтар",
    groups_to_which_student_is_added: "Студент қатысатын топтар",
    hello: "Сәлем",
    hello_teacher: "Сәлем, мұғалім",
    hide: "Жасыру",
    hide_answers: "Жауаптарды жасыру",
    history: "Тарих",
    home: "Басты бет",
    homework: "Үй жұмысы",
    homework_done: "Аяқталды",
    homework_do_homework: "ҮТ орындау",
    homework_is_waiting_for_you: "Бұл сабақ бойынша үй тапсырмасы сізді жеке кабинетте күтіп тұр",
    homework_short: "Ү/т",
    how_it_works: "Ол қалай жұмыс істейді?",
    how_would_you_like_chat_to_respond: "Чатқа қалай жауап беруіңіз келеді?",
    image: "Сурет",
    image_position: "Суреттің орналасуы",
    image_requirements: "Формат — JPEG немесе PNG, көлемі— 5 мегабайттан көп емес",
    incorrect: "Дұрыс емес",
    input_field: "Мәтін жазу аймағы",
    input_from_user: "Пайдаланушының мәтін жазуына арналған аймақ",
    insert_missing_drag: "Қалып қойған сөздерді көшіру",
    insert_missing_drag_requirements: "Қалып қойған сөздерді тік жақшаға [] алып мәтін жазыңыз. Қате нұсқаларды жұлдызша қойып қосуға болады. Қате жауаптарды соңында қою керек. Мысалы, I [like] apples[liking*].",
    insert_missing_open: "Қалып қойған сөздерді жазу",
    insert_missing_select: "Қалып қойған нұсқаларды таңдау",
    insert_missing_select_requirements: "Қалып қойған сөздерді тік жақшаға [] алып мәтін жазыңыз. Нұсқаларды | белгісімен бөліңіз және дұрыс нұсқаны * белгісімен белгілеңіз. Мысалы, He [like|likes*|liking] apples.",
    insert_missing_open_requirements: "Қалып қойған сөздерді тік жақшаға [] алып мәтін жазыңыз. Например, I [like] apples. Дұрыс жауап бірнеше болса, | белгісі арқылы. Мысалы, I [did not|didn't] go. Жұлдызшалар арқылы мәтінді қою етуге болады. Мысалы, what *was* that?",
    integrations: "Интеграциялар",
    interface_language: "Интерфейс тілі",
    internet: "Интернет",
    internet_speed_cannot_be_measured: "Байланыс жылдамдығын анықтау мүмкін емес",
    invitation_link: "Шақырту сілтемесі",
    invitation_sent: "Шақырту жіберілді",
    invitation_sent_to_student: "Шақырту мен кейінгі әрекеттердің нұсқаулығы студенттің email-ына жіберілді",
    invitation_sent_to_teacher: "Шақырту мен кейінгі әрекеттердің нұсқаулығы қызметкердің email-ына жіберілді",
    irregular_verbs: "Бұрыс етістіктер",
    kazakh: "Қазақ",
    keyId: "Кілт ID-ы",
    KZT: "KZT",
    language: "Тіл",
    launch_timer: "Таймер қосу",
    learned: "Оқылды",
    learning: "Оқу процесінде",
    leave_your_data_and_we_contact_you: "Өзіңіз туралы мәлімет қалдырыңыз, біз сізбен хабарласамыз",
    left: "Сол жақтан",
    leftoffset: "Сол жақтан шегініс",
    les_left: "сабақ қалды",
    lesson: "Сабақ",
    lesson_already_begun: "Сабақ басталып кетті",
    lesson_closed: "Сабақ жабылды",
    lesson_constructor: "Сабақ конструкторы",
    lesson_not_found: "Сабақ табылмады",
    lessons: "Сабақтар",
    lesson_has_no_program: "Бұл сабақтың бағдарламасы көрсетілмеген",
    level: "Деңгей",
    light: "Light",
    light_failed_text: "Сіз барлық мүмкіндіктеріңізді пайдаландыңыз, тағы бір тырысып көріңіз, сіздің қолыңыздан келеді!",
    lightcourse: "Light курс",
    lightcourses: "Light курстар",
    link_for_students: "Студенттерге арналған сілтесе",
    link_words: "Сөздерді сәйкестендіру",
    login: "Логин",
    login_details: "Авториязация бөлшектері",
    login_or_pass_incorrect: "Логин және/немесе құпиясөз дұрыс емес",
    login_page: "Авторизация беті",
    logout: "Шығу",
    look_and_remember: "Қараймыз және есімізге сақтаймыз",
    main: "Басты",
    male: "Күйеу",
    manager: "Менеджер",
    managers: "Менеджерлер",
    material: "Сабақтан",
    material_changed: "Сабақ өзгертілді",
    material_deleted: "Сабақ өшірілді",
    materials: "Курстар",
    mediumcourse: "Medium курс",
    memorized: "Есіме сақтадым",
    message: "Хабарлама",
    methodics: "Методика",
    methodist: "Методист",
    methodists: "Методисттер",
    microphone: "Микрофон",
    min: "мин",
    mine: "Менікі",
    minutes: "Минут",
    module: "Модуль",
    modules: "Модульдер",
    more: "Тағы да",
    mute_microfon: "Микрофонды өшіру",
    my_accomplishments: "Менің жетістіктерім",
    my_new_material: "Менің жаңа курсым",
    name: "Есім",
    next: "Әрі қарай",
    new_group: "Жаңа топ",
    new_password: "Жаңа құпиясөз",
    new_schedule: "Жаңа сабақ",
    new_student: "Жаңа студент",
    next_lesson: "Келесі сабақ",
    no_data_available: "Әзірше мәліметтер жоқ",
    no_files_uploaded: "Файлдар жүктелмеген",
    no_lessons_planned: "Әзірше сабақтар жоспарланбаған",
    no_program: "Бағдарламасыз",
    no_students: "Студенттер жоқ",
    no_words_added: "Әзірше сабаққа сөздер қосылмаған",
    not_attended: "Қатысқан жоқ",
    not_checked: "Тексерілмеген",
    not_done: "Жасалмады",
    not_finished: "Аяқталмады",
    not_found: "Мәліметтер табылмады",
    not_memorized: "Есіме сақтамадым",
    not_reviewed: "Тексерілмеген",
    note: "Жазба",
    nothing_found: "Ештеңе табылмады",
    off: "Өшіру",
    on: "Қосу",
    only_video: "Тек видео",
    of_students: "Студенттердің",
    old_password: "Ескі құпиясөз",
    open: "Ашу",
    open_access: "Доступ ашу",
    open_lesson: "Сабақты ашу",
    opened: "Ашық",
    opened_lesson: "Қоғамдық сабақ",
    operations: "Операциялар",
    options: "Нұсқалар",
    order: "Рет",
    paddingbottom: "Төменнен шегініс",
    paddingtop: "Жоғарыдан шегініс",
    pair_hint: "Бір сөзі сол жақтан, екі сөзі оң жақтан көрсетіледі. Оң жақтағы сөзді бос қалдыруға болады, онда сол жақтағы сөздің дұрыс жауабы болмайды. Және оң жақтағы сөзде | таңбасымен бөлінген бірнеше сөзді көрсетуге болады (мысалы, good|awesome), онда ол сөздердің кез келгені оң жақтағы сөз үшін дұрыс жауап болады",
    pairs: "Жұптар",
    page_not_found: "Бет табылмады",
    password: "Құпиясөз",
    password_changed: "Құпиясөз өзгертілді",
    password_confirm: "Құпиясөзді растау",
    passwords_do_not_match: "Құпиясөздер сәйкес емес",
    pause_student: "Студентті тоқтату",
    paused: "Тоқтатылды",
    payment_deleted: "Төлем өшірілді",
    payments: "Төлемдер",
    pdf: "PDF",
    personal_courses: "Менің курстарым",
    phone: "Телефон",
    photo: "Фото",
    photos_from_students: "Студенттерден фото",
    pitch: "Биіктік",
    please_select_the_course: "Курсты таңдаңыз, өтінемін",
    presentation: "Презентация",
    presentation_short: "Презен-я",
    press_on_user_to_follow_him: "Тапсырма орындағанын көру үшін студенттің фотосына басыңыз",
    preview: "Алдын ала көру",
    previous_lesson: "Алдыңғы сабақ",
    price: "Баға",
    price_per_lesson: "Сабақтың бағасы",
    price_policies: "Баға саясаттары",
    price_policy: "Баға саясаты",
    price_policy_deleted: "Баға саясаты өшірілді",
    price_policy_info: "Баға саясаты - студенттің бір сабаққа төлейтін бағасы. Бір немесе бірнеше баға саясатын жасай аласыз. Әр студенттің өзінің шартты балансы бар, оны \"Төлемдер\" бөлімінен толтырып отыра аласыз, баға саясатында көрсетілген бағамен сабақты өткізгеннен кейін, ол шартты баланстан шегеріледі",
    privateKey: "Жасырын кілт",
    program: "Бағдарлама",
    progress: "Прогресс",
    profile: "Профиль",
    question: "Сұрақ",
    question_text: "Сұрақ мәтіні",
    questions: "Сұрақтар",
    quick_actions: "Тез әрекеттер",
    quick_call: "Тез қоңырау",
    radio: "Көлденең радио",
    rate: "Жылдамдық",
    rate_students: "Студенттерді бағалаңыз",
    record_audio: "Аудио жазу",
    recording: "Жазылым",
    referral_system: "Рефералды жүйе",
    refresh: "Жаңарту",
    registration: "Тіркелу",
    repeat: "Қайталау",
    repeating: "Қайталау",
    resend_invite: "Шақырту жіберу",
    repeat_weekly: "Апта сайын қайталау",
    report: "Репорт",
    reset: "Қалпына келтіру",
    reset_password: "Құпиясөзді қалпына келтіру",
    reset_success: "Қалпына келтіру хабарламасы сіздің E-mail-ға жіберілді",
    return_all: "Бәрін қайтару",
    reviewed: "Тексерілді",
    right: "Оң жақтан",
    rights: "Құқықтар",
    role: "Рөл",
    room: "Бөлме",
    rooms: "Бөлмелер",
    rows_count: "Жолдар саны",
    russian: "Орыс тілі",
    redirect_pp: "Баға саясатын ашу",
    save: "Сақтау",
    save_and_add_new_program: "Сақтап, жаңа бағдарлама жасау",
    scan_qr_code_or: "Өз телефоныңыздан немесе QR-код сканерлерңіз",
    schedule: "Кесте",
    schedule_is_empty: "Сабақтар жоқ",
    school: "Мектеп",
    school_courses: "Мектеп курстары",
    school_language: "Мектеп тілі",
    score: "Балл саны",
    scores: "Баллдар",
    script: "Скрипт",
    search: "Іздеу",
    seconds: "Секунд",
    see_responses: "Жауаптар саны",
    see_translation: "Аудармасын көру",
    select: "Таңдау",
    select_course: "Курс таңдау",
    select_topics: "Сабақ тақырыптарын таңдаңыз",
    selected: "Таңдалды",
    selfstudy: "Өзін-өзі оқыту",
    sentence: "Сөйлем",
    sentences: "Сөйлемдер",
    session_halls: "Сессиялық залдар",
    set_password: "Құпия сөзді орнату",
    settings: "Баптаулар",
    signin: "Кіру",
    share_link_with_friend: "Сілтемені досыңызбен бөлісіңіз",
    show: "Көрсету",
    show_answers: "Жауаптарды студенттерге көрсету",
    show_answers2: "Жауаптарын көрсету",
    show_schedule_from: "кестесін көрсету",
    show_schedule_to: "дейін кестесін көрсету",
    show_script_to_users: "Скрпитті студенттерге көрсету",
    slide: "Слайд",
    slide_changed: "Слайд өзгертілді",
    slide_deleted: "Слайд өшірілді",
    slide_not_selected: "Слайд таңдалмады",
    sort_by_columns: "Сөздерді бағандар бойынша сұрыптау",
    sort_by_columns_hint: "Бағанның атауын көрсетіп, сол бағанға қатысты | белгісімен бөлінген сөздердің бәрін тізбектеңіз. Мысалы, He|She|They",
    speaker: "Динамик",
    staff: "Қызметкерлер",
    standard: "Стандартты",
    standard_courses: "Стандартты курстар",
    start: "Бастау",
    start_test: "Тестті бастау",
    started_lesson: "Сабақ басталды",
    step: "Қадам",
    students_will_be_returned_after: "Студенттерді қайтару ... кейін басталады",
    save_and_start: "Сақтау және бастау",
    start_recording: "Жазуды бастау",
    start_training: "Жаттығуды бастау",
    statement: "Бекіту",
    statements: "Бекітулер",
    status: "Статус",
    step_x_out_of: "{steps}-тан Қадам {step}  ",
    stop_and_play: "Тыңдау",
    streaming_type: "Трансляция типі",
    student_added: "Студент қосылды",
    student_changed: "Студент өзгертілді",
    student_deleted: "Студент өшірілді",
    student_note: "Студенттің жазбасы",
    student_responses: "Студенттердің жауаптары",
    students: "Студенттер",
    students_in_group: "Топтағы студенттер",
    subscription: "Жазылу",
    subtitle: "Қол қою",
    sum: "Сома",
    table: "Кесте",
    table_conditions_hint: "Студенттер толтыруы тиіс жауаптарды тік жақшаға алу. Мысалы: [answer]. Қою түспен көрінуі қажет мәтінді жұлдызшамен қоршау. Мысалы: *bold word*. Студенттің дұрыс жауапсыз мәтін жазатын аймағын жасау үшін [] ұяшығын енгізу жеткілікті",
    table_size: "Кестенің өлшемі",
    tariff: "Тариф",
    task: "Тапсырма",
    task_conditions: "Жаттығу шарттары",
    teacher: "Мұғалім",
    teachers: "Мұғалімдер",
    teacher_changed: "Қызметкер өзгертілді",
    teacher_courses: "Мұғалімдер курстары",
    teacher_deleted: "Қызметкер өшірілді",
    teacher_note: "Мұғалімнің жазбасы",
    test: "Тест",
    test_connection: "Байланыс тесті",
    test_question_hint: "Сұрақтарды және минимум 2 жауап нұсқасын толтырыңыз. Дұрыс нұсқа / нұсқаларды құсбелгімен белгілеңіз",
    text: "Мәтін",
    textalign: "Мәтінді туралау",
    textcolor: "Мәтіннің түсі",
    time_limit: "Уақыт бойынша шектеу",
    timer: "Таймер",
    timezone: "Сағат белдеуі",
    title: "Атауы",
    to_school_site: "Мектеп сайтына",
    today: "Бүгін",
    today_schedule: "Бүгінгі сабақ кестесі",
    top: "Жоғарыдан",
    topics: "Тақырыптар",
    total: "Барлығы",
    training: "Жаттығу",
    transcription: "Транскрипция",
    translate_to_all: "Бәріне көрсету",
    translation: "Аударма",
    true: "Дұрыс",
    true_false_hint: "Тұжырымды қосып, дұрыс статусты белгілеңіз: дұрыс, дұрыс емес, белгісіз",
    true_or_false: "Рас немесе жалғанды таңдау",
    true_title: "Батырма атауы 'дұрыс'",
    turn_off_video: "Видеоны өшіру",
    turn_on_microphone: "Микрофонды өшіру",
    turn_on_video: "Видеоны қосу",
    type_missing_letters: "Қалып қойған әріптерді қою",
    type_missing_letters_requirements: "Сөзді / сөз тіркесіп жазып, жасырын болуы керек әр әріптен соң * белгісін қойыңыз. Мысалы, He li*ke*s a*pples.",
    type_text: "Шығарма",
    type_words_by_letters: "Сөзді әріптермен жазу",
    underline_requirements: "Мәтінді жазып, асты сызылуы керек сөздерді тік жақшаға [] алыңыз. Екі рет асты сызылуы керек сөздердің алдына жұлдызшаны, қоршау үшін леп белгісін қолданыңыз. Мысалы, I [like] apples and he [*likes] [!bananas]. Асты сызылмайтын сөздер бір топ болып тұрсын десеңіз, оларды пішінді жақшаларға алыңыз {} (мысалы, {it was raining},  it was raining болып көрінеді, бір топ болып тұрады)",
    underline_words: "Сөздердің астын сызу",
    unknown: "Белгісіз",
    up: "Жоғарыға",
    upload: "Жүктелуде",
    use_as_default: "Әдепкі ретте пайдалану",
    verbs: "Етістіктер",
    video: "Видео",
    video_id_on_youtube: "youtube-тағы Id видео",
    video_url: "Url видео",
    video_url_requirement: "youtube-тан Url видео (мысалы, https://www.youtube.com/watch?v=ZwAERaRUsp0)",
    view: "Қарау",
    view2: "Көрініс",
    visible_to_all: "Бәріне көінеді",
    visibility_options: "Доступ баптаулары",
    visible_to_students: "Студенттерге көрінеді",
    vocabulary: "Сөздік",
    way_of_passing: "Өту тәсілі",
    well_contact_soon: "Біз сізбен жақында хабарласамыз",
    well_done: "Өте жақсы",
    what_would_you_like_chat_to_know_about: "Чаттың қандай қосымша нәрселерді білгенін қалайсыз?",
    when_students_upload_photos_they_will_appear_here: "Студенттер жүктеген соң бұл жерде фотолар пайда болады",
    word: "Сөз",
    word_1: "Сөз 1",
    word_2: "Сөз 2",
    word_cards: "Тілдік карточкалар",
    words: "Сөздер",
    words_for_column: "Осы бағанның сөздері",
    words_training: "Сөздер тренингі",
    workplace: "Жұмыс орыны",
    write_off_lives: "Өмірлерді есептеу",
    yes: "Ия",
    you_dont_have_competed_lessons: "Сіз әзірше бірде-бір сабақты өтпедіңіз",
    you_have_learned: "Сіз оқып шықтыңыз",
    you_have_no_groups: "Сізде топтар жоқ",
    you_need_to_create_at_least_two_students_to_form_group: "Топ қалыптастыру үшін Сізге кем дегенде 2 студент керек",
    your_account_is_already_in_the_class: "Сіздің аккаунтыңыздағы қолданушы сыныпта отыр",
    your_application_sent_successfully: "Сіздің өтінішіңіз жіберілді!",
    your_device_does_not_support_video: "Сіздің құрылғыңызда видеобайланыс технологиясы жоқ - өтінеміз, Safari браузерін қолданыңыз",
    your_friend_invites_you: "Сіздің досыңыз {name} Сізді SkillLMS онлайн-мектебінде ағылшын тілін оқуға шақырады",
    your_homework_is_sent_for_review: "Сіздің үй тапсырмаңыз тексеру үшін мұғалімге жіберілді!",
    your_schedule_is_empty: "Бүгін сіздің сабақтарыңыз жоқ",
    your_teacher: "Сіздің мұғаліміңіз",
    school_student_limit_exceeded: "Мектептегі қол жетімді студенттердің лимиті асып кетті."
}
